@font-face {
  font-family: PoppinsRegular;
  src: url("./fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: PoppinsMedium;
  src: url("./fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: PoppinsBold;
  src: url("./fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: NunitoSansRegular;
  src: url("./fonts/NunitoSans-Regular.ttf");
}

body {
  margin: 0;
}

body,
div,
p,
input,
label,
span,
th,
td {
  font-family: NunitoSansRegular, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
}

.MuiTooltip-tooltip {
  font-size: 0.625rem !important;
}

.MuiTooltip-arrow {
  font-size: 6px !important;
}

.MuiAlert-icon {
  font-size: 22px !important;
}

.MuiAppBar-positionStatic {
  transform: translateZ(0);
}

h3 {
  font-family: PoppinsRegular !important;
  font-size: 24px !important;
}

h4 {
  font-family: PoppinsRegular !important;
  font-size: 20px !important;
}

h6 {
  font-family: PoppinsRegular !important;
  font-size: 15px;
  font-weight: bold;
}

button,
button span {
  font-family: PoppinsMedium !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.p15 {
  padding: 15px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.fr {
  float: right !important;
}

.textTransformNone {
  text-transform: none !important;
}

.colorButton1 {
  background-color: #2F85F1 !important;
  color: white !important;
}

.colorButton1:hover {
  background-color: #3191d6 !important;
}

.colorButton2 {
  background-color: #7ac843 !important;
  color: white !important;
}

.colorButton2:hover {
  background-color: #63b32b !important;
}

.colorButton3 {
  background-color: #ff1d25 !important;
  color: white !important;
}

.colorButton3:hover {
  background-color: #de181f !important;
}

.logoImgPreview {
  width: 100%;
  height: 120px;
  object-fit: contain;
  margin-bottom: 10px;
}
